
export enum ScreenEnum {
  createOrder = "create_order",
  confirmProcess = "confirm_process",
  orderImages = "order_images",
  orderHistory = "order_history",
  orderScan = "order_scan",
  settingEmployeeList = "setting_employee_list",
  settingEmployeeDetail = "setting_employee_detail",
  saveCreateOrder = "save_create_orders",
  saveHistoryOrder = "save_history_order",
  saveImageOrders = "save_image_orders",
  saveManageOrder = "save_manage_orders"
}


const mapLogOrder = (screen: ScreenEnum, orderPrints: any) => {
  return {
    data: orderPrints
  }
}

const mapLogEmployeeList = (employeeList: any) => {
  return  {
    data: {
      employees: employeeList
    }
  }
} 

const mapLogEmployee = (employee: any) => {
  return  {
    data: {
      employee: employee
    }
  }
} 


export const mapSaveLogSystem = (screen: ScreenEnum, data: any) => {

  let response = {
    screen: screen,
    agent: "web",
  }

  if([
    ScreenEnum.saveCreateOrder,
    ScreenEnum.saveImageOrders,
    ScreenEnum.saveHistoryOrder,
    ScreenEnum.saveManageOrder,
    ScreenEnum.orderHistory,
    ScreenEnum.createOrder, 
    ScreenEnum.confirmProcess, 
    ScreenEnum.orderImages, 
    ScreenEnum.orderScan].includes(screen))
  {
    response = {
      ...response,
      ...mapLogOrder(screen, data)
    }
  }else if([ScreenEnum.settingEmployeeList].includes(screen)) {
    response = {
      ...response,
      ...mapLogEmployeeList(data)
    }
  }else if([ScreenEnum.settingEmployeeDetail].includes(screen)) {
    response = {
      ...response,
      ...mapLogEmployee(data)
    }
  }

  return response;
}
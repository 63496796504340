import React, { useState } from 'react';
import { HookContext, TContextHook } from '@/context/hook';
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import { EmptyTask } from "@/components/tasks/blocks/emptyTask";
import { ListTask } from "@/components/tasks/blocks/listTask";


export const TaskPark = ({ ...props }) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);
  
  return (
    // <EmptyTask></EmptyTask>
    <ListTask { ...props } />
  );
};

import React, { useState, useEffect } from 'react';
import { HookContext, TContextHook } from '@/context/hook';
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import moment from 'moment';
import { taskSliceAction } from '@/store/task';


export const ProcessBar = ({ ...props }: any) => {

  const { minTime, maxTime, current_now } = props

  const hooks = React.useContext(HookContext) as TContextHook;
  const stateTask = hooks.useSelector((state: any) => state.task);
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  const stateProfile = hooks.useSelector((state: any) => state.profile);
  const [time, setTime] = useState(["", "", ""]);
  taskVM.setHooks(hooks);
  

  const getLogs = async () => {

    const res = await taskVM.getTaskLog({date: current_now?.format( "YYYY-MM-DD" ) });
    
    hooks.dispatch(
      taskSliceAction.saveLogs(res.data)
    )
  }
  
  const getTotalTime = (log: any) => {
    
    let totalTime = 0;
    if(log.start_time) {
      const endTime = log.end_time ? moment(log.end_time) : moment();
      const duration = moment.duration(moment(log.start_time).diff(endTime));
      totalTime += Math.abs(duration.asSeconds());
    }
    
    
    const totalMinute = totalTime / 60;
    return Math.round(totalMinute * 100) / 100;
  }
  
  const getTotalTimeTask = (log: any) => {
    return (parseFloat(log?.UserProcess?.working_time ?? 0) / 60) + getTotalTime(log);
  }
  
  const getPosition = (log: any) => {
    const duration = moment.duration(moment().diff(moment(stateProfile.first_login_in_day)));
    let totalTimeInDay = Math.abs(duration.asSeconds());
    const minIsNineHour = 9 * 60 * 60;
    if(totalTimeInDay < minIsNineHour) {
      totalTimeInDay = minIsNineHour;
    }
    
    const startAtSecondDuration = moment.duration(moment(log.start_time).diff(moment(stateProfile.first_login_in_day)));
    let startAtSecond = Math.abs(startAtSecondDuration.asSeconds());
    
    return (startAtSecond * 100) / totalTimeInDay; 
  }
  
  const getAllTaskTime = (log: any) => {
    let totalTime = 0;
    
    if(log.UserProcess?.working_time) {
      totalTime = parseFloat(log.UserProcess.working_time);
    }
    
    if(log.start_time && !log.end_time) {
      const endTime = moment();
      const duration = moment.duration(moment(log.start_time).diff(endTime));
      totalTime += Math.abs(duration.asSeconds());
    }
    
    // const hours   = Math.floor(totalTime / 3600);
    // const minutes = Math.floor((totalTime - (hours * 3600)) / 60);
    // const seconds = Math.floor(totalTime - (hours * 3600) - (minutes * 60));
    
    return totalTime / 60;
  }
  
  useEffect(() => {
    
    if(current_now)
    {
      
      getLogs();
      
      const loop = setInterval(() => {
        setTime([
          moment().format("YYYY年MM月DD日"),
          moment().format("HH:mm"),
          moment().format("ss"),
        ]);
      }, 100);
      
      return () => {
        clearInterval(loop);
      }
    }

  }, [ ( current_now ? true : false ) ]);
  
  return (
    <div className="process">
      <p className="time">
        {
          stateProfile.first_login_in_day && moment(stateProfile.first_login_in_day).format("HH:mm")
        }
      </p>
      <div className="blockprocess">
        <ul className="process_list">
          {
            stateTask.logs.filter((item: any) => (item.end_time >= stateProfile.first_login_in_day || !item.end_time))?.map((item: any, key: number) => 
            <li 
              style={{
                width: `${(getTotalTime(item) * 100 / (9*60))}%`,
                backgroundColor: getAllTaskTime(item) > (item.UserProcess?.total_time ?? item.UserProcess?.Task.time) ? "#ff454e" : "#508CED",
                left: `${getPosition(item)}%`
              }}
              key={key}
            >
              <p>{item.UserProcess?.Task?.name}</p>
              <span>{ getTotalTime(item) }<small>分</small></span>
            </li>
            )
          }
        </ul>
      </div>
    </div>
  );
};
